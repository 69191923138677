document.addEventListener("DOMContentLoaded", () => {
	mappingFunc();
	hideElement();
	swiperInit();
	setBackground();
	counterAnimate();
	generalFnc();
});

window.addEventListener("resize", function () {
	let width = this.window.innerWidth;
	if (width <= 1200.1) {
		mappingFunc();
		hideElement();
	}
	if ((width) => 1200.1) {
	}
});

Fancybox.bind("[data-fancybox]", {
	parentEl: document.body[0], // Element containing main structure
});

function generalFnc() {
	//Toggle Javascript code

	const bodyWrap = document.querySelector("body");
	const menuToggle = document.querySelector(".site-menu-toggle");
	const hamburger = document.querySelector(".hamburger");
	const menuMobile = document.querySelector(".mobile-nav-wrap");
	const searchToggle = document.querySelector(".search-toggle");
	const search = document.querySelector(".searchbox");
	const backTop = document.querySelector(".back-to-top");

	//Search Function

	searchToggle.addEventListener("click", () => {
		search.classList.toggle("open");
	});

	// Menu function

	menuToggle.addEventListener("click", () => {
		menuMobile.classList.toggle("is-open");
		hamburger.classList.toggle("is-active");
		bodyWrap.classList.toggle("overlay-bg");
	});

	if ($(window).width() < 1200.1) {
		$(".drop-down .title em").on("click", function () {
			if ($(this).parents(".drop-down").hasClass("is-open")) {
				$(".drop-down .nav-sub").slideUp();
				$(".drop-down").removeClass("is-open");
			} else {
				$(".drop-down .nav-sub").slideUp();
				$(".drop-down").removeClass("is-open");
				$(this).parent().next().slideDown();
				$(this).parents(".drop-down").addClass("is-open");
			}
		});
		$(".tertiary-menu-toggle").on("click", function () {
			$(".nav-tertiary-menu").slideToggle();
		});
	}

	document.addEventListener("click", (event) => {
		if (!event.target.closest(".search-toggle, .searchbox")) {
			search.classList.remove("open");
		}
		if (!event.target.closest(".mobile-nav-wrap, .site-menu-toggle")) {
			menuMobile.classList.remove("is-open");
			bodyWrap.classList.remove("overlay-bg");
			hamburger.classList.remove("is-active");
		}
	});

	//Tab function

	$(".tab-nav li a").on("click", function () {
		$(this).parents(".tab-nav").find("li").removeClass("active");
		$(this).parents("li").addClass("active");

		var display = $(this).attr("data-type");
		$(this).parents("section").find(".tab-item").removeClass("active");
		$("#" + display).addClass("active");
	});
	//Circle Toggle
	if ($(window).width() > 1024.1) {
		$(".circle-carousel .item").on("click", function () {
			$(".circle-carousel .item").removeClass("is-active");
			$(".carousel-txt-wrap .carousel-txt ").removeClass("is-active");
			$(this).addClass("is-active");
			var display = $(this).attr("data-type");
			$("#" + display).addClass("is-active");
		});
	}

	//Next section
	$(".next-section").click(function () {
		var $nextElement = $(".home-about");
		if ($nextElement.length) {
			$("html, body")
				.stop(true)
				.animate(
					{
						scrollTop:
							$nextElement.offset().top - $("header").height(),
					},
					600
				);
		}
		return false;
	});
	//Append Contact Form Service
	if ($("body").hasClass("news-detail-page")) {
		if ($(".service-contact").length) {
			$(".service-contact").insertBefore(".other-service");
		}
	}
}

function swiperInit() {
	var primarySwiper = new Swiper(".primary-banner .swiper", {
		// Optional parameters
		slidesPerView: 1,
		speed: 1205,
		autoplay: {
			delay: 3000,
			pauseOnMouseEnter: true,
		},
		lazy: {
			loadPrevNext: true,
		},
		effect: "fade",
		fadeEffect: {
			crossFade: true,
		},
		navigation: {
			nextEl: ".primary-banner .next",
			prevEl: ".primary-banner .prev",
		},
		pagination: {
			el: ".primary-banner  .swiper-pagination",
			type: "bullets",
			clickable: true,
		},
	});
	const verticalSwiper = new Swiper(".swiper-vertical .swiper", {
		// Optional parameters
		slidesPerView: 1,
		speed: 600,
		autoplay: false,
		lazy: {
			loadPrevNext: true,
		},
		keyboard: {
			enabled: true,
			onlyInViewport: false,
		},
		mousewheel: {
			forceToAxis: true,
			sensitivity: 1,
			releaseOnEdges: true,
		},
		// preventInteractionOnTransition: true,
		simulateTouch: true,
		breakpoints: {
			320: {
				direction: "horizontal",
			},
			1200.1: {
				direction: "vertical",
			},
		},
		navigation: {
			nextEl: ".swiper-vertical .next-icon",
			prevEl: ".swiper-vertical .prev-icon",
		},
		pagination: {
			el: ".swiper-vertical  .swiper-pagination",
			type: "fraction",
		},
		renderCustom: function (swiper, current, total) {
			return current + " of " + total;
		},
	});
	var swiperGrid = new Swiper(".partner-swiper .swiper", {
		autoplay: {
			delay: 3000,
			pauseOnMouseEnter: true,
		},
		lazy: {
			loadPrevNext: true,
		},
		observer: true,
		spaceBetween: 30,
		observeParents: true,
		breakpoints: {
			320: {
				slidesPerView: 2,
			},
			420: {
				slidesPerView: 3,
			},
			768: {
				slidesPerView: 4,
			},
			1200.1: {
				slidesPerView: 5,
				grid: {
					rows: 2,
				},
			},
		},
		navigation: {
			nextEl: ".partner-swiper .next",
			prevEl: ".partner-swiper .prev",
		},
	});

	var projectThumb = new Swiper(".project-thumb  .swiper", {
		spaceBetween: 15,
		breakpointsInverse: true,
		breakpoints: {
			320: {
				slidesPerView: 2,
				direction: "horizontal",
			},
			420: {
				slidesPerView: 3,
				direction: "horizontal",
			},
			768: {
				slidesPerView: 5,
				direction: "horizontal",
			},
			1024.1: {
				slidesPerView: 4,
				direction: "vertical",
				spaceBetween: 24,
			},
		},

		watchSlidesVisibility: true,
		watchSlidesProgress: true,
	});
	var crystalMain = new Swiper(".project-main .swiper", {
		slidesPerView: 1,
		centeredSlides: true,
		lazy: {
			loadPrevNext: true,
		},
		autoplay: {
			delay: 3000,
		},
		navigation: {
			nextEl: ".project-thumb .nav-next",
			prevEl: ".project-thumb .nav-prev",
		},
		speed: 750,
		loop: false,
		thumbs: {
			swiper: projectThumb,
		},
	});

	$(".single-swiper .swiper").each(function (index, element) {
		var $this = $(this);
		$this.addClass("single-instance-" + index);
		$this
			.parent()
			.find(".prev")
			.addClass("btn-prev-single-" + index);
		$this
			.parent()
			.find(".next")
			.addClass("btn-next-single-" + index);
		$this
			.parent()
			.find(".swiper-pagination")
			.addClass("pagination-instance-" + index);

		var swiper = new Swiper(".single-instance-" + index, {
			speed: 1205,
			autoplay: {
				delay: 3000,
				pauseOnMouseEnter: true,
			},
			lazy: {
				loadPrevNext: true,
			},
			speed: 750,
			observer: true,
			spaceBetween: 30,
			observeParents: true,
			slidesPerView: 1,
			navigation: {
				nextEl: ".btn-next-single-" + index,
				prevEl: ".btn-prev-single-" + index,
			},
			pagination: {
				el: ".pagination-instance-" + index,
				type: "bullets",
				clickable: true,
			},
		});
	});
	$(".trinity-swiper .swiper").each(function (index, element) {
		var $this = $(this);
		$this.addClass("tri-instance-" + index);
		$this
			.parent()
			.find(".prev")
			.addClass("btn-prev-tri-" + index);
		$this
			.parent()
			.find(".next")
			.addClass("btn-next-tri-" + index);

		var swiper = new Swiper(".tri-instance-" + index, {
			speed: 1205,
			autoplay: {
				delay: 3000,
				pauseOnMouseEnter: true,
			},
			lazy: {
				loadPrevNext: true,
			},
			speed: 750,
			observer: true,
			spaceBetween: 30,
			observeParents: true,

			breakpoints: {
				200: {
					slidesPerView: 1,
				},
				576: {
					slidesPerView: 2,
				},
				769: {
					slidesPerView: 3,
				},
				1200: {
					slidesPerView: 3,
				},
			},
			navigation: {
				nextEl: ".btn-next-tri-" + index,
				prevEl: ".btn-prev-tri-" + index,
			},
		});
	});

	$(".four-swiper .swiper").each(function (index, element) {
		var $this = $(this);
		$this.addClass("instance-four" + index);
		$this
			.parent()
			.find(".prev")
			.addClass("btn-prev-" + index);
		$this
			.parent()
			.find(".next")
			.addClass("btn-next-" + index);

		var swiper = new Swiper(".instance-four" + index, {
			speed: 1205,
			autoplay: {
				delay: 3000,
				pauseOnMouseEnter: true,
			},
			lazy: {
				loadPrevNext: true,
			},
			speed: 750,
			observer: true,
			spaceBetween: 30,
			observeParents: true,

			breakpoints: {
				200: {
					slidesPerView: 1,
				},
				420: {
					slidesPerView: 2,
				},
				769: {
					slidesPerView: 3,
				},
				1280: {
					slidesPerView: 4,
				},
			},
			navigation: {
				nextEl: ".btn-next-" + index,
				prevEl: ".btn-prev-" + index,
			},
		});
	});
}

// Side
function setBackground() {
	const elements = document.querySelectorAll("[setBackground]");
	elements.forEach((element) => {
		element.style.cssText = `
		background-image: url(${element.getAttribute("setBackground")});
		background-size: cover;
		background-position: center;
	  `;
	});
}
function counterAnimate() {
	const counterItem = document.querySelectorAll(".counter");
	if (counterItem.length) {
		const counterUp = window.counterUp.default;
		const callback = (entries) => {
			entries.forEach((entry) => {
				const el = entry.target;
				if (
					entry.isIntersecting &&
					!el.classList.contains("is-visible")
				) {
					counterUp(el, {
						duration: 2000,
						delay: 200,
					});
					el.classList.add("is-visible");
				}
			});
		};
		const IO = new IntersectionObserver(callback, { threshold: 1 });

		counterItem.forEach((counter) => {
			IO.observe(counter);
		});
	}
}

function mappingFunc() {
	new MappingListener({
		selector: ".nav-primary-menu",
		mobileWrapper: ".mobile-nav-wrap",
		mobileMethod: "appendTo",
		desktopWrapper: ".header-wrap-right",
		desktopMethod: "insertBefore",
		breakpoint: 1200.1,
	}).watch();
}
function hideElement() {
	if ($(window).width() < 1200.1) {
		const [menu] = [".nav-primary-menu"].map((selector) =>
			document.querySelector(selector)
		);

		[menu].forEach((el) => (el.style.display = "flex"));
	}
}
